<template>
  <section class="mepandes" v-if="mepandes.length">
    <div
      class="heading text-center with-border mar-bottom-40"
      data-aos="fade-up"
    >
      <h2 class="title text-uppercase text-teal-dark">Mepandes</h2>
      <div class="sub-title">Potong Gigi</div>
    </div>

    <ul class="text-center persons px-2">
      <li
        class="person"
        data-aos="fade-up"
        v-for="person in mepandes"
        :key="person.name"
      >
        <!-- <img src="@/assets/icons/arrow.svg" /> -->
        <img :src="person.image" class="image" />
        <div class="name">{{ person.name }}</div>
        <!-- <img src="@/assets/icons/arrow.svg" class="flip-horinzontal" /> -->
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  props: ["mepandes"],
};
</script>

<style lang="scss" scoped>
.mepandes {
  .person {
    align-items: center;
    margin-bottom: 1.5rem;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 0.5rem;
  }
  .image {
    width: 100px;
    margin-right: 1rem;
    opacity: 1;
  }

  .name {
    text-align: left;
    font-weight: bold;
    flex-grow: 1;
  }

  .person:nth-child(even) {
    .image {
      order: 2;
      margin-left: 1rem;
      margin-right: 0;
    }

    .name {
      text-align: right;
    }
  }

  @media (min-width: 768px) {
    .persons {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .person {
        width: 20%;
        margin-left: 2%;
        margin-right: 2%;
        display: block;
        padding: 0;
        overflow: hidden;
        margin-bottom: 2rem;

        .image {
          width: 100%;
          margin: 0;
        }

        .name {
          text-align: center;
          padding: 1rem 1.5rem;
        }
      }
    }
  }

  @media (min-width: 992px) {
    .persons {
      .person {
        width: 12%;
      }
    }
  }
}
</style>
